import moment from 'moment/moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge, Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { reducer, sendRequest } from '../../../utils';
import ProductSelector from '../Components/ProductSelector';
import CustomerSelector from '../Components/CustomerSelector';

const { REACT_APP_API_URL } = process.env;
const STATUSES = {
  new: 'New',
  queued: 'Queued',
  success: 'Success',
  error: 'Failed',
};

// Function to group rules by customer name with additional unique ID for each group of rules
// Example:
// [
//   {
//     id: 1,
//     customer: {},
//     rules: []
//   }
// ]
const groupRulesByCustomer = (rules) => {
  const groupedRules = rules.reduce((acc, rule) => {
    const { customer: { name: customerName, repository: customerRepository } } = rule;

    const id = `${customerName} | ${customerRepository}`;
    if (!acc[id]) {
      acc[id] = [];
    }
    acc[id].push(rule);
    return acc;
  }, {});

  return Object.entries(groupedRules).map(([, rules], index) => ({
    id: (new Date()).getTime() + index,
    customer: rules[0].customer,
    rules,
  }));
};

const groupRulesByProduct = (rules) => rules.reduce((acc, rule) => {
  const { product: { name: productName } } = rule;
  if (!acc[productName]) {
    acc[productName] = [];
  }
  acc[productName].push(rule);
  return acc;
}, {});

function AutopushRuleListItem({ customer, rules }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: rules, message: '', error: '', isLoading: false, isError: false,
    },
  );

  const handlePushClick = (ruleId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `autopush-rules/${ruleId}/push`,
      method: 'POST',
      onSuccess: (response, message) => {
        dispatch({ type: 'FETCH_SUCCESS', message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  const handlePushAllClick = (customerId) => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: `autopush-rules/push-all/${customerId}`,
      method: 'POST',
      onSuccess: (response, message) => {
        dispatch({ type: 'FETCH_SUCCESS', message });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  };

  const handleDeleteAutopushRule = (ruleId) => {
    if (window.confirm('Are you sure you want to delete this autopush rule?')) {
      dispatch({ type: 'FETCH_INIT' });

      sendRequest({
        route: `autopush-rules/${ruleId}`,
        method: 'DELETE',
        onSuccess: (response, message) => {
          const rules = state.data.filter((rule) => rule.id !== ruleId);
          dispatch({ type: 'FETCH_SUCCESS', payload: rules, message });
        },
        onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
      });
    }
  };

  return (
    <>
      <h5 className="d-flex justify-content-between align-items-center mb-2">
        <span className="flex-fill">
          {
            customer.avatar && (
              <img
                src={customer.avatar}
                alt={customer.name}
                className="me-2"
                style={{ maxWidth: '50px', maxHeight: '35px' }}
              />
            )
          }
          <span className="align-middle">
            {`${customer.company_name} (${customer.name})`}
            <span className="subheader mx-2">{customer.repository}</span>
          </span>
        </span>
        {state.isLoading && <Spinner animation="border" />}

        {!state.isLoading && (
          <Button as={NavLink} onClick={() => handlePushAllClick(customer.id)} size="sm" variant="outline-primary">
            Push All
          </Button>
        )}
      </h5>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error || 'Something went wrong. Please try again later.'}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        state.data.length === 0 && (
          <Alert variant="info">
            No autopush rules found
          </Alert>
        )
      }

      {
        Object.entries(groupRulesByProduct(state.data)).map(([productName, productRules]) => (
          <React.Fragment key={productName}>
            <h6 className="d-flex justify-content-between align-items-center mx-4 mb-2">
              <span>
                {
                  productRules[0].product.image && (
                    <img
                      src={productRules[0].product.image}
                      alt={productName}
                      className="me-2"
                      style={{ maxWidth: '50px', maxHeight: '35px' }}
                    />
                  )
                }
                <span className="align-middle">
                  {productName}
                </span>
              </span>
            </h6>

            <Table className="text-center" size="sm" bordered>
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Branch</th>
                  <th>Last Push Status</th>
                  <th>Last Push Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                productRules.map((rule) => (
                  <tr key={rule.id} className={rule.need_to_push ? 'bg-danger' : 'bg-success'} style={{ '--bs-bg-opacity': '.15' }}>
                    <td>
                      <Badge bg="primary" className="me-2">
                        {rule.version}
                      </Badge>
                    </td>
                    <td>
                      <Badge bg="secondary" className="me-2">
                        {rule.version}
                            &nbsp;&rarr;&nbsp;
                        {rule.branch.name}
                        <br />
                        {
                          rule.branch.main_branch && (
                            <i className="pt-1 d-inline-block">
                              {`(main branch is "${rule.branch.main_branch}")`}
                            </i>
                          )
                        }
                      </Badge>
                    </td>
                    <td>
                      {
                        rule.status === 'error' ? (
                          <Badge bg="danger" className="me-2">
                            {STATUSES[rule.status]}
                          </Badge>
                        ) : (
                          <Badge bg="success" className="me-2">
                            {STATUSES[rule.status]}
                          </Badge>
                        )
                      }
                    </td>
                    <td>{moment.utc(rule.date_pushed).local().format('MMMM Do YYYY, HH:mm:ss')}</td>
                    <td>
                      <Button
                        as={state.isLoading ? Button : NavLink} // This is a hack to disable the button when loading
                        to={`${rule.id}`}
                        variant="outline-secondary"
                        size="sm"
                        className="me-2 mb-2"
                        disabled={state.isLoading}
                      >
                        Edit
                      </Button>

                      {
                        rule.latest_task_id && (
                          <Button
                            href={`${REACT_APP_API_URL}/flower/task/${rule.latest_task_id}`}
                            target="_blank"
                            variant="outline-secondary"
                            size="sm"
                            className="me-2 mb-2"
                            disabled={state.isLoading}
                          >
                            Latest Task
                          </Button>
                        )
                      }

                      <Button
                        variant="outline-primary"
                        onClick={() => handlePushClick(rule.id)}
                        size="sm"
                        className="me-2 mb-2"
                        disabled={state.isLoading}
                      >
                        Push
                      </Button>

                      <Button
                        variant="outline-danger"
                        onClick={() => handleDeleteAutopushRule(rule.id)}
                        size="sm"
                        className="me-2 mb-2"
                        disabled={state.isLoading}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </React.Fragment>
        ))
      }
    </>
  );
}

AutopushRuleListItem.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    repository: PropTypes.string.isRequired,
  }).isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    customer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      avatar: PropTypes.string,
      name: PropTypes.string.isRequired,
      company_name: PropTypes.string.isRequired,
      repository: PropTypes.string.isRequired,
    }).isRequired,
    version: PropTypes.string.isRequired,
    branch: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      main_branch: PropTypes.string,
    }).isRequired,
    date_pushed: PropTypes.string.isRequired,
  })).isRequired,
};

function AutopushRulesList() {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );
  // Filters
  const [groupedRules, setGroupedRules] = React.useState([]);
  const [productId, setProductId] = React.useState(null);
  const [customerId, setCustomerId] = React.useState(null);
  const [onlyNeedToPush, setOnlyNeedToPush] = React.useState(false);
  const [onlyFailed, setOnlyFailed] = React.useState(false);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest({
      route: 'autopush-rules',
      method: 'GET',
      body: {'per_page': 1000},
      onSuccess: (data) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: data.rules });
      },
      onError: (error) => { dispatch({ type: 'FETCH_FAILURE', error }); },
    });
  }, []);

  React.useEffect(() => {
    let filteredRules = state.data;

    if (productId) {
      filteredRules = filteredRules.filter((rule) => rule.product_id === Number(productId));
    }

    if (customerId) {
      filteredRules = filteredRules.filter((rule) => rule.customer_id === Number(customerId));
    }

    if (onlyNeedToPush) {
      filteredRules = filteredRules.filter((rule) => rule.need_to_push);
    }

    if (onlyFailed) {
      filteredRules = filteredRules.filter((rule) => rule.status === 'error');
    }

    const groupedRules = groupRulesByCustomer(filteredRules);

    setGroupedRules(groupedRules);
  }, [state.data, productId, customerId, onlyNeedToPush, onlyFailed]);

  return (
    <Row className="mb-4">
      <h3 className="d-flex justify-content-between align-items-center mb-4">
        Autopush Rules
        {state.isLoading && <Spinner animation="border" />}
      </h3>

      <Button as={NavLink} to="new" variant="primary" className="mb-4">Create New Rule</Button>

      <Col>
        <Form>
          <Row className="align-items-center">
            <Col sm="12" md="4">
              <ProductSelector
                name="product_id"
                label="Filter by Product"
                onChange={setProductId}
              />
            </Col>

            <Col sm="12" md="4">
              <CustomerSelector
                name="customer_id"
                label="Filter by Customer"
                onChange={setCustomerId}
              />
            </Col>

            <Col sm="12" md="4">
              <Form.Check
                type="checkbox"
                id="onlyNeedToPush"
                label="Only show rules that need to be pushed"
                onChange={(e) => setOnlyNeedToPush(e.target.checked)}
              />

              <Form.Check
                type="checkbox"
                id="onlyFailed"
                label="Only show rules that failed"
                onChange={(e) => setOnlyFailed(e.target.checked)}
              />
            </Col>
          </Row>
        </Form>
      </Col>

      {state.isError && (
        <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
          {state.error || 'Something went wrong'}
        </Alert>
      )}

      {state.message && (
        <Alert variant="success">
          {state.message}
        </Alert>
      )}

      {
        groupedRules.length
          ? (
            groupedRules.map(
              (groupedRule) => (
                <Col md="12" className="mb-4" key={groupedRule.id}>
                  <AutopushRuleListItem customer={groupedRule.customer} rules={groupedRule.rules} />
                </Col>
              ),
            )
          )
          : (
            <Alert variant="info">
              No autopush rules found
            </Alert>
          )
      }
    </Row>
  );
}

export default AutopushRulesList;
